







// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';

// --- Services ---

// --- Third Party imports ---
import Hashids from 'hashids';

@Component
export default class BookingResponsePage extends Vue {
  hashids = new Hashids(process.env.VUE_APP_HASHIDS_SALT);

  async beforeMount() {
    // get the unencoded shoot id from the url (it is coming from DB via email link so can't be hashed)
    const id: string = this.hashids.decode(this.$route.params.id) + '';

    if (id && id.length > 0) {
      try {
        this.$router.push({ name: 'Shoot Detail', params: { id: '' + this.hashids.encode(parseInt(id, 10)) } } ); 
      } catch (error) {
        // kick  them out, we couldn't decode the id as an int
        this.$router.push({ name: 'My Shoots' });
      }
    } else {
      // kick  them out, how did they even get here?!
      this.$router.push({ name: 'My Shoots' });
    }
  }
}
